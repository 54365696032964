.wrapper {
  width: 100%;
}

.galleryWrapper {
  composes: main from 'containers';
  composes: cropped from 'containers';

  margin-top: var(--space-l);
  overflow: hidden;
}

.gallery {
  margin: 0 var(--space-s-neg);
}

.card {
  width: 568px;
  height: 700px;
  padding: 0 var(--space-s);
}

.track {
  margin-top: var(--space-l);
}

@media (--desktop-m) {
  .card {
    width: 400px;
    height: 488px;
  }
}

@media (--desktop) {
  .gallery {
    margin: 0 var(--space-xs-neg);
  }

  .card {
    width: 300px;
    height: 366px;

    padding: 0 var(--space-xs);
  }
}

@media (--mobile-xxs) {
  .card {
    width: 270px;
    height: 330px;
  }
}
