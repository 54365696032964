.wrapper {
  --items-gap: var(--space-s);
  composes: main from 'containers';

  width: 100%;
  overflow: hidden;
}

.gallery {
  margin: 0 calc(var(--items-gap) * -1);
}

.categoryItem {
  box-sizing: border-box;
  width: 100%;
  max-width: calc(100% / 8);
  padding: 0 var(--items-gap);
}

.progressBar {
  margin-top: var(--space-l);
}

@media (--desktop-m) {
  .categoryItem {
    min-width: 219px;
  }
}

@media (--mobile-m) {
  .wrapper {
    --items-gap: var(--space-xs);
  }

  .categoryItem {
    width: 165px;
    min-width: unset;
    max-width: unset;
  }
}
