.wrapper {
}

.galleryWrapper {
  overflow: hidden;
}

.gallery {
  overflow: hidden;
}

.galleryContainer {
  margin: 0 var(--space-s-neg);
}

.itemWrapper {
  flex: 0 0 auto;
  box-sizing: border-box;
  width: 100%;
  padding: 0 var(--space-s);
}

.headerWrapper {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin: 0 var(--space-xxb-neg) var(--space-xxs);

  @media (--desktop-m) {
    margin: 0 var(--space-xxl-neg) var(--space-xxs);
  }

  @media (--mobile-m) {
    margin: 0 var(--space-s-neg) var(--space-xxs);
  }
}

.container {
  composes: main from 'containers';
}
