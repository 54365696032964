.pageIndex {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
}

.container {
  composes: main from 'containers';
}

.map {
  display: block;
}

.instagram {
  display: block;
}

.descriptionWrapper {
  composes: main from 'containers';

  margin-bottom: var(--space-e);
}

.section {
  width: 100%;
  margin-bottom: var(--space-e);

  @media (--desktop-m) {
    margin-bottom: var(--space-xb);
  }
}

.banner {
  width: 100%;
  margin-bottom: var(--space-xb);

  @media (--mobile-m) {
    margin-bottom: var(--space-xxl);
  }
}

.galleryPlaceholder {
  height: 790px;
  @media (--desktop-m) {
    height: 640px;
  }

  @media (max-width: 1535px) {
    height: 570px;
  }

  @media (--desktop) {
    height: 506px;
  }

  @media (--mobile-m) {
    height: 406px;
  }

  @media (--mobile) {
    height: 606px;
  }
}

@media (--desktop-m) {
  .descriptionWrapper {
    margin-bottom: var(--space-xb);
  }
}
