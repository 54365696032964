.gallery {
  margin: 0 calc(var(--items-gap) * -1);
}

.galleryItem {
  vertical-align: top;
}

.productItem {
  position: relative;

  width: 22vw;
  min-width: 145px;
  max-width: 221px;
  padding: 0 var(--items-gap);
}

@media (--desktop) {
  .wrapper {
    --items-gap: var(--space-xs);
  }
}

@media (--mobile-m) {
  .wrapper .productItemUzb {
    min-width: 217px;
  }
}

@media (--mobile-xxs) {
  .productItem {
    min-width: 260px;
  }
}
