.galleryWrapper {
  position: relative;

  overflow: hidden;
}
.galleryWrapper.empty {
  height: 100%;

  background-color: var(--color-light-gray);
}

.bannerContainer {
  position: relative;
}

.subwrapper {
  flex-shrink: 0;
  width: 100%;
}

.infoPopup {
  position: absolute;
  top: var(--space-n);
  right: var(--space-s);
  z-index: var(--z-index-popup);
}

.arrows {
  max-height: 450px;
}

.gallery {
  position: relative;

  max-width: var(--max-content-width);
  margin: 0 auto;
}

@media (--desktop) {
  .arrowWrapper {
    display: none;
  }
}

@media (--mobile-m) {
  .infoPopup {
    top: var(--space-xs);
    right: var(--space-xs);
  }
}
