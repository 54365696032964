.container {
  composes: main from 'containers';

  padding: var(--space-xb) var(--space-xxb);
  overflow: hidden;

  background-color: #d8e4e2;
}

.header {
  margin-bottom: var(--space-xb);
}

.nestedCard {
  display: grid;
  grid-auto-rows: 1fr;
  gap: var(--space-n);
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

.galleryTrack {
  margin-top: var(--space-n);
}

.cardWrapper {
  width: 282px;
  height: 540px;
  padding: 0 var(--space-xs);

  &:nth-child(odd) {
    width: 278px;
  }

  &:is(:first-child) {
    padding-left: 0;
  }
  &:is(:last-child) {
    padding-right: 0;
  }
}

@media (--desktop-m) {
  .container {
    padding: var(--space-b) var(--space-xxl);
  }
  .header {
    margin-bottom: var(--space-b);
  }

  .cardWrapper {
    height: 500px;
  }
}

@media (--mobile-m) {
  .container {
    padding: var(--space-l) var(--space-s);
  }

  .header {
    margin-bottom: var(--space-xxl);
  }

  .cardWrapper {
    width: 160px;
    height: 305px;
    padding: 0 var(--space-xxs) 0 0;

    &:nth-child(odd) {
      width: 160px;
    }

    &:is(:last-child) {
      padding-right: 0;
    }
  }

  .nestedCard {
    gap: var(--space-xxs);
  }
}
