.card {
  position: relative;

  box-sizing: border-box;

  width: 100%;
  height: 100%;
  padding: var(--space-n);

  background-color: var(--color-light-gray);
}

.sticker {
  font-size: var(--size-s);
}

.imgContainer {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
}

.img {
  background-color: var(--color-white);
}

.textContainer {
  position: relative;
  z-index: 1;

  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.name {
  font-weight: 500;
  font-size: var(--size-m);
  line-height: 150%;
}

.link {
  color: var(--color-black);
  text-decoration: none;
}

@media (--desktop) {
  .name {
    font-size: var(--size-n);
  }
}

@media (--mobile-m) {
  .card {
    padding: var(--space-xs);
  }
  .name {
    max-width: 94px;

    font-size: var(--size-s);
  }
  .sticker {
    font-size: var(--size-xs);
  }
}
