.wrapper {
  --items-gap: var(--space-s);

  width: 100%;
}

.gallery {
  margin: 0 calc(var(--items-gap) * -1);
}

.item {
  width: 422px;
  min-width: 260px;
  max-width: 422px;
  padding: 0 var(--items-gap);
}

@media (--desktop-m) {
  .item {
    width: 400px;
  }
}

@media (--desktop) {
  .wrapper {
    --items-gap: var(--space-xs);
  }

  .item {
    width: 300px;
  }
}

@media (--mobile-xxs) {
  .item {
    width: 260px;
  }
}
